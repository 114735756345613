export const onInitialClientRender = () => {
    window.dataLayer = window.dataLayer || [];

    const consent = localStorage.getItem('cookieConsent') || 'denied';

    window.dataLayer.push({
        event: 'cookie_consent_update',
        analytics_storage: consent,
        ad_storage: consent,
    });

    console.log('Consent pushed to dataLayer:', consent);
};